export const devices: TableModule = {
    name: "devices",
    route: "spa.baseData.devices",
    columns: [
        {
            name: "id",
            sortable: true,
            mobile: true,
            width: "w-[80px]"
        },
        {
            name: "name",
            sortable: true,
            pageLink: true,
            mobile: true
        },
        {
            name: "net_price",
            sortable: true,
            mobile: true
        },
        {
            name: "status",
            sortable: true,
            mobile: true
        },
    ],
    filters: [
        {
            name: "id",
            column: "devices.id",
            type: "numeric"
        },
        {
            name: "name",
            column: "devices.name",
            type: "string"
        },
        {
            name: "net_price",
            column: "devices.net_price",
            type: "numeric"
        },
        {
            name: "status",
            column: "devices.status",
            type: "select",
            options: [
                {
                    value: true,
                    label: "Aktiv"
                },
                {
                    value: false,
                    label: "Inaktiv"
                }
            ]
        }
    ],
    quickFilters: [
        {
            name: "active",
            column: "devices.status",
            operator: "=",
            value: true,
            default: true
        }
    ],
    tools: [
        {
            name: "devices.create",
            isCreateAction: true,
            ability: ['create', 'devices']
        },
        {
            name: "devices.status",
            color: "success",
            icon: "heroicons:check-circle"
        },
        {
            name: "devices.delete",
            color: "rose",
            icon: "heroicons:trash"
        },
        {
            name: "general.export",
            icon: "heroicons:arrow-down-tray"
        }
    ]
}