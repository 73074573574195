import {users} from './tableModules/users'
import {organisations} from "./tableModules/organisations";
import {loginProviders} from './tableModules/loginProviders'
import {contacts} from './tableModules/contacts'
import {categories} from './tableModules/categories'
import {costCenters} from './tableModules/costCenters'
import {devices} from './tableModules/devices'
import {mailTemplates} from "~/utils/tableModules/mailTemplates";

export const tableModules: TableModules = {
    users,
    organisations,
    testUsers: {
        ...users,
        name: 'testUsers'
    } as TableModule,
    contacts,
    loginProviders,
    categories,
    costCenters,
    devices,
    mailTemplates
}
