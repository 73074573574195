export const categories: TableModule = {
    name: "categories",
    route: "spa.baseData.categories",
    columns: [
        {
            name: "id",
            sortable: true,
            mobile: true,
            width: "w-[80px]"
        },
        {
            name: "name",
            sortable: true,
            mobile: true,
            pageLink: true,
        },
        {
            name: "description",
            sortable: true,
        }
    ],
    filters: [
        {
            name: "id",
            column: "categories.id",
            type: "numeric"
        },
        {
            name: "name",
            column: "categories.name",
            type: "string"
        }
    ],
    quickFilters: [],
    tools: [
        {
            name: "categories.create",
            isCreateAction: true,
            ability: ['create', 'categories']
        },
        {
            name: "general.export",
            icon: "heroicons:arrow-down-tray"
        }
    ]
}