export const mailTemplates: TableModule = {
    name: "mailTemplates",
    route: "spa.settings.mailTemplates",
    defaultOrder: {
        column: "name",
        direction: "asc"
    },
    columns: [
        {
            name: "name",
            pageLink: true,
            sortable: true
        },
        {
            name: "created_at",
            sortable: true,
            mobile: true
        },
        {
            name: "updated_at",
            sortable: true,
            mobile: true
        }
    ],
}